<template>
  <div>
    <div class="top">
      <img :src="require('@/assets/image/index_02.png')">
    </div>
    <div class="content" >
      <div class="ss"><input  type="text" placeholder="请输入案由进行搜索" v-model="Search.Case_Action" /><span @click="search()">搜索</span></div>
      <div class="list" v-if="flag">
        <ul v-for="(item, i) in list" :key="i" >
          <li>
            <div class="ybj" v-if="item.this_Status === 8"><img :src="require('@/assets/image/ybj.png')" width="110" /></div>
            <div class="left">会议名称：</div><div>{{ item.meet_Name }}</div>
            <div class="left">案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由：</div><div class="t">{{ item.case_Action}}</div>
            <div class="left">环&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;节：</div><div class="hj">{{ item.node_Name }}</div>
            <div class="left">案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</div><div>提案</div>
            <div class="left">领衔人员：</div><div >{{ item.staff_Name}}</div>
            <div class="hengBtn">
              <div class="k nml" @click="toInfo(item)">详情查看</div>
              <div class="k" @click="toLine(item)">流程查看</div>
              <div class="k bj"  v-if="item.this_Status === 0" @click="toEdit(item)">编辑</div>
              <div class="k ch" v-if="item.this_Status === 1" @click="handleRestDel(item)">撤回</div>
            </div>
          </li>
        </ul>
      </div>
      <van-empty description="暂无案件信息，请重新查询" v-else/>
        <br>
      <br>
    </div>
    <time-line ref="timeLine" />
  </div>
</template>

<script>
  import store from "@/store";
  import Base64 from "@/util/Base64";
  import timeLine from '@/views/timeline'
  import {getList, resetNpcApp} from '@/api/appManagement'
  import {Toast} from "vant";
  import {Emas} from "@/assets/js/Emas";
  export default {
    name: "index",
    components: {timeLine},
    data() {
      return {
        flag: true,
        list:[],
        Search: {
          This_Status: -2,
          Case_Action: '',
          Operator: 0,
          Role_Type: 1, //1人大/政协、2代表团界别、3人大工委政协提案委、4市委督察室
          Type: 2,
          DLG_ID: 0,
        },
        queryForm: {
          intPageIndex: 1,
          intPageSize: 999,
          strOrderByFileds: 'ID DESC',
        },
      }
    },

    created() {
      let userInfo = store.getters['user/userInfo']
      this.Search.Operator = userInfo.user_ID
      this.Search.DLG_ID = userInfo.dlg_ID
      this.fetchData()
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      console.log(userInfo)
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/cppcc", "政协", "zzdzx.yy.gov.cn/cppcc")
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        });
        const { data } = await getList(this.queryForm, this.Search)
        this.list = data.data
        this.flag = data.data.length > 0
        Toast.clear()
      },

      //撤回
      async handleRestDel(row) {
        const { success, msg } = await resetNpcApp({ App_ID: row.id })
        if (success) {
          await Toast.success("撤回成功")
        } else {
          await Toast.fail("撤回失败")
        }
        await this.fetchData()
      },

      search() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.fetchData()
      },

      toEdit(row) {
        this.$router.push({
          path: '/editApp',
          query: {
            App_ID: Base64.encode(row.id),
            Type: Base64.encode(1)
          }
        })
      },

      toLine(row) {
        this.$refs['timeLine'].showTimeLine(row)
      },

      toInfo(row) {
        this.$router.push({
          path: '/info',
          query: {
            App_ID: Base64.encode(row.id),
          }
        })
      }
    }
  }
</script>

<style scoped>
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }
  body,div,ul,li{ padding:0px; margin:0px; list-style:none; font-size:16px;}
  .top { width:100%; text-align:center;}
  .top img { width:100%}
  .content{background:#fff; position:relative; margin-top:-15px;border-radius: 15px;padding-top: 10px;}
  /*list*/
  .list {  width:94%; margin:auto; margin-top:10px;}
  .ss{width:90%;margin:auto;padding: 2%; border-radius:16px;margin-top:10px; background:#FFF;-webkit-filter: drop-shadow(0 0 4px #ccc);filter: drop-shadow(0 0 4px #ccc); height:40px; line-height:40px;}
  .ss  input { width: 65%; line-height:40px; border:none; padding-left:20px;font-size: 16px;}
  .ss span {float:right; background:#ff7800; border-radius:10px; text-align:center;line-height: 40px;color: #fff;;width: 22%;}
  .list li { padding:16px; border-radius:16px;margin-top: 20px; background:#FFF;-webkit-filter: drop-shadow(0 0 4px #ccc);filter: drop-shadow(0 0 4px #ccc);}
  .list div{ line-height:30px;}
  .list .k{ width:80px; height:30px; border-radius:10px; background:#fff; border:1px #7f7f7f solid; text-align:center; float:left; margin-left:10px;}
  .list .nml { margin-left:0px;}
  .list .bj { border:1px #1187ff solid; color:#1187ff; width:60px;}
  .list .ch { border:1px #ff4c4c solid; color:#ff4c4c; width:60px;}
  .list .left { width:90px;float:left;/*text-align: justify;*/text-align:right}
  .list .hj { color:#1ca800;}
  .list .t { color:#cf0000;}
  .list .hengBtn{height:36px; margin-top:6px;}
  .list .ybj {position:absolute;z-index:999;margin-left: 60%;margin-top: 10%;}
</style>