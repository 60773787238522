<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      width="80%"
      top="4vh"
      :close-on-click-modal="false"
      @close="close"
    >
      <div
        :style="{
          height: scrollerHeight,
          overflow: 'auto',
        }"
      >
        <br />
        <el-timeline v-loading="loading" :reverse="reverse">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :timestamp="activity.create_Time"
            icon="el-icon-more"
            type="primary"
          >
            {{ activity.title }}
            {{ activity.staff_Name }}
            {{ activity.node_Name }}
            {{ activity.content }}

            <br />
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import store from "@/store";
  import { Emas } from "@/assets/js/Emas";
  import { getAppLogList } from '@/api/appManagement'

  export default {
    name: 'Index',
    data() {
      return {
        title: '时间线',
        dialogVisible: false,
        activities: [],
        reverse: true,
        loading: false,
      }
    },
    computed: {
      scrollerHeight: function () {
        return window.innerHeight - 280 + 'px'
      },
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/timeline", "时间线", "zzdzx.yy.gov.cn/timeline")
    },
    methods: {
      async showTimeLine(row) {
        this.dialogVisible = true
        this.loading = true
        const { data } = await getAppLogList({ App_ID: row.id })
        this.loading = false

        data.forEach((item) => {
          item.create_Time = item.create_Time.replace('T', ' ')
          this.activities.push(item)
        })
        console.log(this.activities)
      },

      close() {
        this.activities = []
        this.dialogVisible = false
      },
    },
  }
</script>

<style scoped></style>
