import { request } from '@/util/request'

export function addApp(data) {
  return request({
    url: '/api/App/AddApp',
    method: 'post',
    data,
  })
}

export function updateApp(data) {
  return request({
    url: '/api/App/UpdateApp',
    method: 'post',
    data,
  })
}

export function getAppInfo(params) {
  return request({
    url: '/api/App/GetAppInfo',
    method: 'get',
    params,
  })
}

export function resetNpcApp(params) {
  return request({
    url: '/api/App/ResetNpcApp',
    method: 'get',
    params,
  })
}

export function doDelete(params) {
  return request({
    url: '/api/App/DelApp',
    method: 'get',
    params,
  })
}

export function getList(params, data) {
  return request({
    url:
      '/api/App/GetList?intPageIndex=' +
      params.intPageIndex +
      '&intPageSize=' +
      params.intPageSize +
      '&strOrderByFileds=' +
      params.strOrderByFileds,
    method: 'post',
    data: data,
  })
}

export function getSearchList(params, data) {
  return request({
    url:
      '/api/App/GetSearchList?intPageIndex=' +
      params.intPageIndex +
      '&intPageSize=' +
      params.intPageSize +
      '&strOrderByFileds=' +
      params.strOrderByFileds,
    method: 'post',
    data: data,
  })
}

export function getSearchPeruseList(params, data) {
  return request({
    url:
      '/api/App/GetSearchPeruseList?intPageIndex=' +
      params.intPageIndex +
      '&intPageSize=' +
      params.intPageSize +
      '&strOrderByFileds=' +
      params.strOrderByFileds,
    method: 'post',
    data: data,
  })
}

export function approveApp(data) {
  return request({
    url: '/api/App/ApproveApp',
    method: 'post',
    data,
  })
}

export function updateCaseAction(data) {
  return request({
    url: '/api/App/UpdateCaseAction',
    method: 'post',
    data,
  })
}

export function updateCasePropertyID(data) {
  return request({
    url: '/api/App/UpdateCasePropertyID',
    method: 'post',
    data,
  })
}

export function finalApp(data) {
  return request({
    url: '/api/App/FinalApp',
    method: 'post',
    data,
  })
}

export function getAppLogList(params) {
  return request({
    url: '/api/AppLog/GetAppLogList',
    method: 'get',
    params,
  })
}
